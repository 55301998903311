<template>
  <div id="print">
    <div class="d-flex justify-content-center align-items-center mt-3">
      <h5>Laporan Pendapatan Klinik Hayandra</h5>
      <!-- <h5>{{moment().format("dd-mm-yyyy")}}</h5> -->
    </div>

    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px"
        class="font-weight-bold"
      >Total Terbayar :</span>
      <!-- <span class="font-weight-bold">{{ summary.length != 0 ? "Rp. " + parseInt(summary[0].final_amount).toLocaleString('id-ID') : '' }}</span> -->
      <span class="font-weight-bold">{{ "Rp. " + parseInt(summary).toLocaleString('id-ID')}}</span>
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
    >
      <template #cell(medical_list)="data" >
        <div
          v-for="(service, index) in data.item.services"
          :key="index"
        >
          <li>
            <span class="text-sizing">
              {{ `${service.name} : ${parseInt(service.price).toLocaleString('id-ID')}` }}
            </span>
          </li>
        </div>
      </template>
      <template #cell(total_amount)="data" class="text-sizing">
        <span class="text-sizing">  
          {{ parseInt(data.item.total_amount).toLocaleString('id-ID') }}
        </span> 
      </template>
      <template #cell(tax)="data" class="text-sizing">
        <span class="text-sizing">
          {{ parseInt(data.item.tax).toLocaleString('id-ID') }}
        </span>
      </template>
      <template #cell(discount)="data" class="text-sizing">
        <span class="text-sizing">
          {{ parseInt(data.item.discount).toLocaleString('id-ID') }}
        </span>
      </template>
      <template #cell(final_amount)="data" class="text-sizing">
        <span class="text-sizing">
          <ul>
            <li>Total :{{ parseInt(data.item.final_amount).toLocaleString('id-ID') }}</li>
            <li>Terbayar :{{ parseInt(data.item.paid_amount).toLocaleString('id-ID') }}</li>
          </ul>
        </span>
      </template>
      <template #cell(surcharge)="data" class="text-sizing">
        <span class="text-sizing">

        {{ parseInt(data.item.surcharge).toLocaleString('id-ID') }}
        </span>
      </template>
    </b-table>

  </div>
</template>

<script>

import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import module from '@/core/modules/CrudModule.js'
import moment from 'moment'

export default {

  props: {
    hasPatient: Boolean,
    items: Array,
    summary: Number,
    perPage: Number,
    currentPage: Number,
    totalRows: Number
  },

  components: {
    Treeselect
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: '',
        patient_name: '',
        start_date: '',
        end_date: ''
      },
      // Options
      doctors: [],
      // field
      fields: [
        {
          key: "number",
          label: "#",
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "patient_name",
          label: "Pasien",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "doctor_name",
          label: "Dokter",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },

        {
          key: "medical_list",
          label: "Daftar Layanan",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "total_amount",
          label: "Subtotal",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "tax",
          label: "Pajak",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "discount",
          label: "Diskon",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "surcharge",
          label: "Surcharge",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
        {
          key: "final_amount",
          label: "Total",
          thClass: "text-sizing",
          tdClass: "text-sizing"
        },
      ],
    }
  },

  methods: {


    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect('doctors')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Filter Berdasar Dokter', id: '', isDisabled: true })
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.$emit('pageOnClick', page)
    },

    resetFilter() {
      this.filter.doctor_id = ''
      this.filter.start_date = ''
      this.filter.end_date = ''
    },

    filterByPeriode() {
      this.$emit('filter', this.filter)
    },

    async filterByDoctor() {
      await this.filter.doctor_id
      this.$emit('filter', this.filter)
    },

    filterByPatient() {
      this.resetFilter()
      this.$emit('filterByPatient', this.filter)
    },

    btnDeleteOnClick(id) {
      this.$emit('btnDeleteOnClick', id)
    }

  },

  watch: {
    'filter.doctor_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.doctor_id = ''
        this.filterByDoctor()
      }
    }
  },

}
</script>

<style>
.text-sizing{
  font-size: 10px;
}
</style>