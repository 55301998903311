<template>
  <div>
   

    <div class="d-flex justify-content-start align-items-center mt-5">
      <!-- <button
        class="btn btn-success mr-1"
        v-b-tooltip.hover
        title="Grafik"
        @click="$router.push('/report/payment-chart')"
      >
        <i class="fas fa-chart-bar px-0"></i>
      </button> -->
      <button
        class="btn btn-primary mr-1"
        v-b-tooltip.hover
        title="Print"
        @click="btnPrintOnClick"
      >
        <i class="fas fa-print px-0"></i>
      </button>
      <button
        class="btn btn-info mr-1"
        v-b-tooltip.hover
        title="Download Pdf"
        @click="btnDownloadOnClick"
      >
        <i class="fas fa-file-pdf px-0"></i>
      </button>
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button>
    </div>

    <div class="d-flex justify-content-end align-items-center mt-6">
      <span
        style="width: 150px; color: #a12260 !important"
        class="font-weight-bolder"
        >Total :</span
      >
      <span class="font-weight-bolder" 
        style="color:#a12260 !important;"
      >{{ "Rp. " + parseInt(summary).toLocaleString('id-ID')}}</span>
      <!-- >{{ summary.length != 0 ? "Rp. " + parseInt(summary[0].total).toLocaleString('id-ID') : '' }}{{this.sum}}</span> -->
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      responsive="sm"
      style="white-space: nowrap"
    >
      <template #cell(total)="data">
        Rp
        {{
          parseInt(
            data.item.total
          ).toLocaleString("id-ID")
        }}
      </template>
      <template #cell(price)="data">
        
        {{ data.item.quantity +" X Rp "+
          parseInt(
            data.item.price
          ).toLocaleString("id-ID")
        }}
      </template>
    </b-table>
    

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Laporan Pendapatan Apotek'"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
        :items="items"
        :summary="summary"
        
        />
      </section>
    </vue-html2pdf>

  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf"
import Print from '@/component/reports-print/PaymentPharmacyReport.vue'
import XLSX from 'xlsx';
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'


export default {
  props: {
    // hasPatient: Boolean,
    // items: Array,
    // listData: Array,
    // summary: Number,
    // perPage: Number,
    // currentPage: Number,
    // totalRows: Number
    dataModal:Array,
    excel:Array,
  },

  components: {
    Treeselect,
    XLSX,
    VueHtml2pdf,
    Print
  },

  data() {
    return {
      doctors: [],
     // Filter
      filter: {
        doctor_id: '',
        patient_name: '',
        start_date: '',
        end_date: ''
      },
      total: 0,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Items
      items: [],
      excelData: [],
      listData: [],
      summary: 0,
      dataLoaded:false,

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        // {
        //   key: "code",
        //   label: "Kode",
        //   sortable: false,
        // },
        {
          key: "created_at_display",
          label: "Tanggal",
          sortable: true,
        },
  
        {
          key: "medicine_name",
          label: "Nama Obat/Alkes",
          sortable: true,
        },
        
        {
          key: "price",
          label: "Kuantitas & harga",
          sortable: true,
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
        },
      ],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    };
  },

  methods: {
    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    // pageOnClick(evt, page) {
    //   evt.preventDefault()
    //   this.currentPage = page
    //   this.pagination()
    // },

    // resetFilter() {
    //   this.filter.start_date = ''
    //   this.filter.end_date = ''
    //   this.filter.doctor_id = ''
    //   this.filter.patient_name = ''
    //   this.pagination()
    // },

    async pagination() {
    
      this.excelData = this.excel
      this.items = this.dataModal

      console.log(this.dataModal,this.excel,"dump");
      
      // console.log(this.items,this.excelData,this.items.length,"Ini bgsdddddd")
      this.summaryData()
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.excelData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'Laporan Pendapatan Apotek.xlsx')
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },


    summaryData(){
      let a,b
      this.summary = 0
      for (a = 0; a < this.items.length; a++) {
        
          this.summary += parseInt(this.items[a].medicine[this.items[a].medicine.length-1].total)
          console.log(parseInt(this.items[a].medicine[this.items[a].medicine.length-1].total));
        
      }
      console.log("summary",this.summary);
    }




  },

  // watch: {
  //   "filter.doctor_id": function (newVal, oldVal) {
  //     if (typeof newVal === "undefined") {
  //       this.filter.doctor_id = "";
  //       this.filterByDoctor();
  //     }
  //   },
  // },

  mounted() {
    // Get Options
    // this.getDoctorsOption()
    // this.summary()

    this.pagination()
  }

}
</script>

<style>
</style>